import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  bootstrapApplication,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routing';

import { AppHammerConfig } from './app/configs/hammer-config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      AuthModule.forRoot({
        domain: `auth.waltio.com`,
        clientId: environment.auth0.clientID,
        authorizationParams: {
          redirect_uri: environment.auth0.callback,
          audience: `https://api.waltio.co/`,
          scope: `openid email`,
        },
        cacheLocation: `localstorage`,
        httpInterceptor: {
          allowedList: [`https://data.waltio.com/*`],
        },
      })
    ),
    provideRouter(routes),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig,
    },
  ],
})
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    if (window[`ngRef`]) {
      window[`ngRef`].destroy();
    }
    window[`ngRef`] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));
