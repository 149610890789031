// Hammer Config Overide

import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

// https://github.com/angular/angular/issues/10541
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides: any = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}
