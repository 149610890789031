import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IntercomService } from '../../services/intercom.service';

@Component({
  selector: `app-landing-page`,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDividerModule],
  templateUrl: `./landing-page.component.html`,
  styleUrls: [`./landing-page.component.scss`],
})
export class LandingPageComponent implements OnInit {
  isMobile = false;
  isTablet = false;
  isDesktop = false;

  constructor(
    private readonly router: Router,
    private readonly intercomService: IntercomService,
    private readonly deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();

    this.intercomService.updatePageName(`landing`);
  }

  redirectToAccounts(): void {
    this.router.navigateByUrl(`/accounts`);
  }
}
