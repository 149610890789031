import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: `app-custom-toast`,
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: `./custom-toast.component.html`,
  styleUrls: [`./custom-toast.component.scss`],
  animations: [
    trigger(`fade`, [
      transition(`:enter`, [
        style({ transform: `translateX(-100%)` }),
        animate(
          `300ms 0ms cubic-bezier(0, 0.98, 0.69, 0.99)`,
          style({ transform: `translateX(0)` })
        ),
      ]),
      transition(`:leave`, [
        style({ transform: `translateX(0)` }),
        animate(
          `300ms 0ms cubic-bezier(1, 0, 1, 1)`,
          style({ transform: `translateX(-100%)` })
        ),
      ]),
    ]),
  ],
})
export class CustomToastComponent implements OnChanges {
  @Input() error: any;
  @Input() showToast = false;
  @Input() duration = 4000; // 4s
  @Input() interval = 100; // 1s
  @Input() type: string;

  @Output() closeToast: EventEmitter<void> = new EventEmitter();

  timer: any;
  progress = 100;

  ngOnChanges(changes: SimpleChanges): void {
    this.progress = 100;

    if (changes.showToast?.currentValue) {
      this.timer = setInterval(() => {
        this.progress -= (this.interval / this.duration) * 100;

        if (this.progress <= 0) {
          this.closeToast.emit();
        }
      }, this.interval);
    }
  }

  close(): void {
    clearInterval(this.timer);
    this.closeToast.emit();
  }
}
