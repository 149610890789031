import { Injectable } from '@angular/core';
import LedgerLiveApi, {
  Account,
  Currency,
  WindowMessageTransport,
} from '@ledgerhq/live-app-sdk';

@Injectable({
  providedIn: `root`,
})
export class LedgerService {
  api: LedgerLiveApi = new LedgerLiveApi(new WindowMessageTransport());

  constructor() {
    this.api.connect();
  }

  getAccounts(): Promise<Account[]> {
    return this.api.listAccounts({
      includeTokens: true,
    });
  }

  getCurrencies(): Promise<Currency[]> {
    return this.api.listCurrencies();
  }
}
