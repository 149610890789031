@if (showToast) {
  <section
    class="toast"
    [ngClass]="{
      'toast--success': type === 'success',
      'toast--error': type === 'error'
    }"
    @fade
    >
    <!-- {{ type }} -->
    <mat-progress-bar
      class="custom-progress-bar"
      [ngClass]="{
        'custom-progress-bar--success': type === 'success',
        'custom-progress-bar--error': type === 'error'
      }"
      mode="determinate"
      [value]="progress"
    ></mat-progress-bar>
    <div class="content">
      @if (type === 'success') {
        <img src="/assets/img/icons/success-check.svg" alt="success" />
        <p>Your wallet has been successfully added to your Waltio account.</p>
      }
      @if (type === 'error') {
        @if (type === 'error') {
          <img src="/assets/img/icons/error.svg" alt="error" />
        }
        <p>
          {{ error?.error.message || 'Something went wrong when we tried to add your wallet.' }}
        </p>
      }
      <img class="pointer" src="/assets/img/icons/cross-light.svg" alt="cross" (click)="close()" />
    </div>
  </section>
}
