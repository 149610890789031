import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AccountsPageComponent } from './pages/accounts-page/accounts-page.component';
import { CallbackPageComponent } from './pages/callback-page/callback-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

export const routes: Routes = [
  {
    path: ``,
    redirectTo: `landing`,
    pathMatch: `full`,
  },
  {
    path: `landing`,
    component: LandingPageComponent,
  },
  {
    path: `accounts`,
    component: AccountsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `callback`,
    component: CallbackPageComponent,
  },
];
