import { Injectable } from '@angular/core';

import CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: `root`,
})
export class IntercomService {
  /**
   * Update intercom settings
   */
  updateSettings(email: string, userId: string): void {
    const user_hash = CryptoJS.HmacSHA256(
      userId,
      environment.intercom.secret
    ).toString(CryptoJS.enc.Hex);

    // @ts-ignore
    window[`intercomSettings`] = {
      ...window[`intercomSettings`],
      app_id: `ea8g7nxz`,
      user_id: userId,
      user_hash,
      email, // Email address
      created_at: new Date(), // Signup date as a Unix timestamp
    };
  }

  /**
   * Update intercom page name
   *
   * @param pageName
   */
  updatePageName(pageName: string): void {
    window[`intercomSettings`] = {
      ...window[`intercomSettings`],
      pagename: pageName,
    };
  }

  /**
   * Update intercom language
   *
   * @param language
   */
  updateLanguage(language: string): void {
    window[`intercomSettings`] = {
      ...window[`intercomSettings`],
      language_override: language,
    };

    window[`Intercom`](`boot`, window[`intercomSettings`]);
  }
}
