import { Directive, Input } from '@angular/core';

/* eslint-disable */
@Directive({
  standalone: true,
  selector: 'img[default]',
  host: {
    '(error)': 'updateSrc()',
    '[src]': 'src',
  },
})
export class ImagePreloadDirective {
  @Input() src: string;
  @Input() default: string;

  updateSrc(): void {
    let token = this.src.split('https://icons.waltio.com/token/')[1]?.toUpperCase();
    token = token?.startsWith('REALTOKEN') ? 'REALTOKEN' : token;

    switch (token) {
      case 'REALTOKEN':
        this.src = 'https://icons.waltio.com/token/reg';
        break;
      default:
        this.src = this.default;
    }
  }
}
