/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Tag } from '../models/tag.model';

@Injectable({
  providedIn: `root`,
})
export class TrackingService {
  pushTag(tag: Tag): void {
    let market: string;

    switch (tag.market) {
      case `FR`:
        market = `france`;
        break;
      case `BE`:
        market = `belgium`;
        break;
      case `ES`:
        market = `spain`;
        break;
      default:
        market = `france`;
    }

    window[`dataLayer`].push({
      event: tag.event,
      environment_name: environment.production ? `production` : `dev`,
      environment_market: market,
      user_login_status: tag.loginStatus,
      user_type: tag.plan === `Free` ? `registered` : `customer`,
    });
  }
}
