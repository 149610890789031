<div class="global-container">
  <header>
    <img src="/assets/img/logos/waltio-white.svg" alt="waltio" width="150px" height="40px" />
    <img src="/assets/img/icons/cross.svg" alt="logo" />
    <img src="/assets/img/logos/ledger-white.svg" alt="ledger" width="150px" height="40px" />
  </header>

  <section
    [ngClass]="{
      container: isDesktop,
      'mobile-container': isMobile || isTablet
    }"
    >
    <h1>Waltio is partnering with Ledger to offer you the easiest way to get your crypto taxes done.</h1>
    <h2>100% compliant with local tax regulations in 🇫🇷 France, 🇧🇪 Belgium and 🇪🇸 Spain.</h2>

    <!-- Mobile steps -->
    @if (isMobile || isTablet) {
      <mat-divider></mat-divider>
      <div class="mobile-steps">
        <div class="step">
          <div class="step-title">
            <div class="step-index">1</div>
            <h3>Login or Signup to Waltio</h3>
          </div>
          <div class="step-content">
            <img src="/assets/img/steps/step-1.svg" alt="step-1" />
          </div>
        </div>
        <div class="step">
          <div class="step-title">
            <div class="step-index">2</div>
            <h3>Connect your Ledger wallets</h3>
          </div>
          <div class="step-content">
            <img src="/assets/img/steps/step-2.svg" alt="step-2" />
          </div>
        </div>
        <div class="step">
          <div class="step-title">
            <div class="step-index">3</div>
            <h3>Get your report on tax.waltio.com</h3>
          </div>
          <div class="step-content">
            <img src="/assets/img/steps/step-3.svg" alt="step-3" />
          </div>
        </div>
      </div>
    }

    <!-- Desktop steps -->
    @if (isDesktop) {
      <div class="steps">
        <div class="step">
          <div class="step-content">
            <img src="/assets/img/steps/step-1.svg" alt="step-1" />
          </div>
          <div class="step-title">
            <div class="step-index">1</div>
            <h3>Login or Signup to Waltio</h3>
          </div>
        </div>
        <div class="step">
          <div class="step-content">
            <img src="/assets/img/steps/step-2.svg" alt="step-2" />
          </div>
          <div class="step-title">
            <div class="step-index">2</div>
            <h3>Connect your Ledger wallets</h3>
          </div>
        </div>
        <div class="step">
          <div class="step-content">
            <img src="/assets/img/steps/step-3.svg" alt="step-3" />
          </div>
          <div class="step-title">
            <div class="step-index">3</div>
            <h3>Get your report on tax.waltio.com</h3>
          </div>
        </div>
      </div>
      <button type="button" mat-flat-button color="primary" (click)="redirectToAccounts()">Connect</button>
    }
  </section>

  @if (isMobile || isTablet) {
    <div class="ctas">
      <button type="button" mat-flat-button color="primary" (click)="redirectToAccounts()">Connect</button>
    </div>
  }
</div>
