import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: `app-callback-page`,
  standalone: true,
  imports: [],
  templateUrl: `./callback-page.component.html`,
})
export class CallbackPageComponent implements OnInit {
  constructor(private readonly router: Router) { }

  ngOnInit(): void {
    this.router.navigateByUrl(`/accounts`);
  }
}
