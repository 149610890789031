import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: `truncate`,
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(
    value = ``,
    limit = 20,
    completeWords = false,
    ellipsis = `...`
  ): string {
    if (!value) {
      return ``;
    } else if (value.length <= limit) {
      return value;
    }

    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(` `);
    }

    return `${value.substring(0, limit)}${ellipsis}`;
  }
}
