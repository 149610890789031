import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../models/generic-response.model';
import { IzanamiResponse } from '../models/izanami-response.model';
import { APIDetails, Upload } from '../models/upload.model';
import { AcquisitionChannel, User } from '../models/user.model';
import { AccountListResponse } from '../models/waltio-account.model';

@Injectable({
  providedIn: `root`,
})
export class WaltioService {
  constructor(private readonly http: HttpClient) { }

  getWaltioAvailableAccounts(): Observable<AccountListResponse> {
    return this.http.get<AccountListResponse>(
      `${environment.apiUrl}/v1/tax/account/list`
    );
  }

  /**
   *  Get all features from Izanami
   *
   * @param page
   * @param pageSize
   * @param pattern
   * @returns
   */
  getFeatures(
    page = 1,
    pageSize = 9999,
    pattern = `*`
  ): Observable<IzanamiResponse> {
    return this.http.get<IzanamiResponse>(
      `${environment.apiUrl}/v1/tax/app/features?page=${page}&pageSize=${pageSize}&pattern=${environment.features.pattern}${pattern}`
    );
  }

  /**
   * Check if address is already supported
   *
   * @param platform
   * @param address
   * @returns
   */
  checkAddress(platform: string, address: string): Observable<GenericResponse> {
    return this.http.get<GenericResponse>(
      `${environment.apiUrl}/v1/tax/api/${platform}/exists?address=${address}`
    );
  }

  /**
   * Create API
   *
   * @param platform
   * @param api
   */
  createAPI(
    account: string,
    address: string,
    alias: string
  ): Observable<Upload> {
    const api: APIDetails = {
      key: address,
    };

    return this.http.post<Upload>(
      `${environment.apiUrl}/v1/tax/api/${account}?aggregator=LEDGER&alias=${alias}`,
      api
    );
  }

  /**
   * Get user
   */
  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/v1/tax/user`);
  }

  /**
   * Set user acquisition channel
   *
   * @param channel
   * @returns
   */
  setUserAcquisitionChannel(channel: AcquisitionChannel): Observable<User> {
    return this.http.put<User>(
      `${environment.apiUrl}/v1/tax/user/acquisition-channel/${channel}`,
      {}
    );
  }
}
